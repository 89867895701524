import parse from "html-react-parser";

import { Box, List, ListItem } from "@chakra-ui/react";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import React from "react";
import { blockSpacing } from "../lib/getBlockSpacing";

const IconList = ({
	listItems,
	iconSource,
	textColor,
	blockPadding,
	mobileBlockPadding,
	blockMargin,
	mobileBlockMargin,
}) => {
	const Icon = (props) => {
		return iconSource && !!React.cloneElement(parse(iconSource)[0], props);
	};

	const regex = /(?:^<p[^>]*>)|(?:<\/p>$)/g;
	return (
		<List
			spacing={3}
			width="100%"
			{...blockSpacing(
				blockPadding,
				mobileBlockPadding,
				blockMargin,
				mobileBlockMargin
			)}
		>
			{listItems.map((item, index) => {
				return !!item?.itemContent && (
					<ListItem key={index} display="flex" alignItems="center">
						<Box as={Icon} display="inline-flex" flexShrink="0" mr={3} />
						<Box display="inline-flex" color={textColor}>
							<Prose>
								{parse(item.itemContent.replace(regex, ""))}
							</Prose>
						</Box>
					</ListItem>
				);
			})}
		</List>
	);
};

export default IconList;
