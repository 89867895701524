import fourstars from "@images/4stars.svg";
import fivestars from "@images/5stars.svg";
import clsx from "clsx";
import parse from "html-react-parser";
import Image from 'next/image'

import SecondaryButton from "@components/SecondaryButton";

interface IProps {
  text: string;
  stars: string;
  target: object;
  size: "xl" | "lg" | "md" | "sm";
}

const Review = ({ size, text, stars = "5Stars", target }) => {
  let width;
  if (size == "sm" || size == "md") {
    width = 100;
  } else {
    width = 150;
  }

  if (target.text == "Learn More") {
    target.text = "Read Reviews";
  }

  //console.log(stars + ' ' + text)

  return (
    <div
      className={clsx("Reviews_reviewContent text-center", {
        "text-3xl": size === "xl",
        "text-xl": size === "lg",
        "text-lg": size === "md",
        "text-base": !size || size === "sm",
      })}
      style={{ fontSize: size }}
    >
      <Image
        src={stars === "5Stars" || stars == null ? fivestars : fourstars}
        layout="fixed"
        alt="5-stars"
        width={stars === "4Stars" ? width * 0.8 : width}
      />
      {!!text && parse(text)}

      {target?.url && (
        <div className={clsx("text-xs mt-3")}>
          <SecondaryButton
            target={target}
            legacyBackgroundColor={"transparent"}
            borderColor={"transparent"}
            textColor={"#6863F2"}
            hoverBackgroundColor={"transparent"}
            hoverTextColor={"#6863F2"}
          />
        </div>
      )}
    </div>
  );
};

export default Review;
