import clsx from "clsx";
import Iframe from "react-iframe";

const IframeEmbed = ({ url }) => {
  return (
    <div className={clsx("IframeEmbed", "max-w-screen-lg mx-auto")}>
      <Iframe
        url={url}
        width="100%"
        id="myId"
        position="relative"
        className={clsx("block", "h-[900px] w-full")}
      />
    </div>
  );
};

export default IframeEmbed;
