import clsx from "clsx";
import PageSectionBlocks from "@components/PageSectionBlocks";

import { Box, Flex } from "@chakra-ui/react";

import { headerHeight } from "@lib/headerHeight";

import { getStyleObjectFromString } from "../lib/getStyleObjectFromString";
import { blockSpacing } from "../lib/getBlockSpacing";
import { sectionNavHeight } from "../../apps/abnormal/lib/headerHeight";

import {
	Link,
	Button,
	Element,
	Events,
	animateScroll as scroll,
	scrollSpy,
	scroller,
} from "react-scroll";

const Background = ({
	backgroundImage,
	backgroundOverlay,
	backgroundOverlayPosition,
}) => {
	return (
		<>
			{!!backgroundOverlay?.url && backgroundOverlayPosition && (
				<Box
					className={clsx(
						"Container__backgroundOverlay hidden lg:block",
						"absolute inset-0",
						{
							"bg-left-top": backgroundOverlayPosition === "topLeft",
							"bg-right-top": backgroundOverlayPosition === "topRight",
							"bg-left-bottom": backgroundOverlayPosition === "bottomLeft",
							"bg-right-bottom": backgroundOverlayPosition === "bottomRight",
							"bg-center": backgroundOverlayPosition === "center",
							"bg-bottom": backgroundOverlayPosition === "bottomCenter",
							"bg-top": backgroundOverlayPosition === "topCenter",
						}
					)}
					w="full"
					h="full"
					bgImage={backgroundOverlay.url}
					bgSize="contain"
					bgRepeat="no-repeat"
				></Box>
			)}

			{!!backgroundImage?.url && (
				<Box
					className={clsx(
						"Container__backgroundImage",
						"h-full w-full max-w-screen-3xl m-auto inset-0 absolute z-0"
					)}
					bgImage={backgroundImage.url}
					bgSize="cover"
					bgPos="center"
					bgRepeat="no-repeat"
				/>
			)}
		</>
	);
};

const Container = ({
	maxWidth,
	backgroundColor,
	backgroundFullWidth,
	backgroundImage,
	backgroundImageBlur,
	customCss,
	children,
	horizontalAlignment,
	verticalAlignment,
	gridMode,
	gridGap,
	gridColumns,
	reverseOrderOnMobile,
	backgroundOverlay,
	backgroundOverlayBlur,
	backgroundOverlayPosition,
	isFirstPageSection = false,
	anchorName,
	gridPosts,
	isFirstContainer = false,
	forms,
	globals,
	hideOnMobile,
	hideOnDesktop,
	disableNavigationPadding,
	blockPadding,
	mobileBlockPadding,
	blockMargin,
	mobileBlockMargin,
	sectionNav,
}) => {
	return (
		<Box
			as={Element}
			name={anchorName}
			width={"100%"}
			display={{
				base: hideOnMobile ? "none" : "block",
				md: hideOnDesktop ? "none" : "block",
			}}
			className="Container"
			{...blockSpacing(
				blockPadding,
				mobileBlockPadding,
				blockMargin,
				mobileBlockMargin
			)}
		>
			{anchorName && (
				<Box
					as="a"
					className="anchor"
					id={anchorName}
					display="block"
					position="relative"
					top={
						sectionNav
							? `${0 - headerHeight - sectionNavHeight}px`
							: `${0 - headerHeight}px`
					}
					visibility="hidden"
				></Box>
			)}

			<Box
				width={"100%"}
				pos="relative"
				className="Container__wrapper"
				backgroundColor={backgroundFullWidth && backgroundColor}
				sx={
					backgroundFullWidth &&
					!!customCss &&
					getStyleObjectFromString(customCss)
				}
			>
				<Flex
					maxW={maxWidth}
					w="100%"
					justify={horizontalAlignment}
					gap={gridMode && gridGap && { base: "sm", md: gridGap }}
					align={verticalAlignment}
					flexDirection={
						reverseOrderOnMobile
							? { base: "column-reverse", md: "row" }
							: { base: "column", md: "row" }
					}
					display={gridMode ? { base: "flex", md: "grid" } : "flex"}
					pos="relative"
					zIndex={10}
					mx="auto"
					flexWrap="wrap"
					px={maxWidth === "max-w-screen-xl" && 5}
					backgroundColor={!backgroundFullWidth && backgroundColor}
					pt={
						sectionNav &&
						!disableNavigationPadding &&
						isFirstPageSection &&
						headerHeight
							? sectionNavHeight + headerHeight + 32
							: !disableNavigationPadding && isFirstPageSection && headerHeight
							? headerHeight + 32
							: null
					}
					gridTemplateColumns={{
						base: gridMode && "repeat(1, 1fr)",
						md: gridMode && `repeat(${gridColumns}, 1fr)`,
					}}
					className={clsx("Container__content")}
					sx={
						!backgroundFullWidth &&
						!!customCss &&
						getStyleObjectFromString(customCss)
					}
				>
					{children &&
						children.map((child, index) => {
							//console.log(isFirstPageSection || isFirstContainer);
							return (
								<PageSectionBlocks
									section={child}
									key={index}
									gridMode={gridMode}
									gridPosts={gridPosts}
									gridColumns={gridColumns}
									containerMaxWidth={maxWidth}
									forms={forms}
									globals={globals}
									subColumn={child.typeHandle === "column" && gridMode}
									isFirstContainer={isFirstPageSection || isFirstContainer}
								/>
							);
						})}
					{!backgroundFullWidth && (
						<Background
							backgroundImage={backgroundImage}
							backgroundImageBlur={backgroundImageBlur}
							backgroundOverlay={backgroundOverlay}
							backgroundOverlayBlur={backgroundOverlayBlur}
							backgroundOverlayPosition={backgroundOverlayPosition}
						/>
					)}
				</Flex>
				{backgroundFullWidth && (
					<Background
						backgroundImage={backgroundImage}
						backgroundImageBlur={backgroundImageBlur}
						backgroundOverlay={backgroundOverlay}
						backgroundOverlayBlur={backgroundOverlayBlur}
						backgroundOverlayPosition={backgroundOverlayPosition}
					/>
				)}
			</Box>
		</Box>
	);
};

export default Container;
