import clsx from "clsx";
import { Children, useEffect, useState } from "react";
import { A11y, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Box } from "@chakra-ui/react";
import { ArrowLeft, ArrowRight } from "@components/Icons";
import { useWindowSize } from "@lib/helpers";

const getSlidesOffset = (width) => {
	const offset = Math.round((width - 1792) / 2);

	let gutterWidth = 60;

	if (width < 1024) gutterWidth = 30;

	return offset > 0 ? offset + gutterWidth : gutterWidth;
};

const EntryCarousel = ({
	children,
	columns = 2,
	isFlush = true,
	thumbnailImageHeight = null,
	isFeatured = false,
	entriesPerSlide = 3,
	entryBackgroundColor = null,
	showDots = true,
}) => {
	const { width } = useWindowSize();

	const [swiper, setSwiper] = useState(null);
	const [isBeginning, setIsBeginning] = useState(true);
	const [isEnd, setIsEnd] = useState(false);
	const [offset, setOffset] = useState(null);

	useEffect(() => {
		setOffset(getSlidesOffset(width));
	}, [width]);

	const handleSlideChange = () => {
		setIsBeginning(swiper.isBeginning);
		setIsEnd(swiper.isEnd);
	};

	const NavButton = ({ direction = "next" }) => {
		const handleClick = () => {
			if (direction === "next") swiper.slideNext();
			if (direction === "prev") swiper.slidePrev();
		};

		return (
			<button
				className={clsx(
					"w-12 h-12",
					"bg-white-01",
					"rounded-full",
					"flex items-center justify-center",
					"absolute top-0 z-30 -translate-y-1/2",
					"transition-all",
					"hover:translate-x-2",
					{
						"opacity-0 pointer-events-none":
							(direction === "prev" && isBeginning) ||
							(direction === "next" && isEnd),
						"right-4 lg:right-7.5": direction === "next",
						"left-4 lg:left-7.5": direction === "prev",
					}
				)}
				onClick={handleClick}
				style={{ top: thumbnailImageHeight / 2 }}
			>
				{direction === "next" ? <ArrowRight /> : <ArrowLeft />}
			</button>
		);
	};

	return (
		<div className={clsx("EntryCarousel")}>
			<div
				className={clsx(
					"EntryCarousel__content max-w-screen-xl mx-auto px-5",
					"relative"
				)}
			>
				<Swiper
					modules={[Pagination, A11y]}
					pagination={showDots && { clickable: true }}
					a11y={{
						prevSlideMessage: "Previous slide",
						nextSlideMessage: "Next slide",
					}}
					slidesPerView={1.25}
					slidesPerGroup={1}
					enabled={entriesPerSlide < children.length}
					spaceBetween={isFlush ? 0 : 30}
					slidesOffsetBefore={0}
					slidesOffsetAfter={0}
					onSwiper={setSwiper}
					onSlideChange={handleSlideChange}
					allowTouchMove={true}
					breakpoints={{
						640: {
							slidesPerView: 1,
							spaceBetween: isFlush ? 0 : 60,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: isFlush ? 0 : 60,
						},
						1024: {
							slidesPerView: entriesPerSlide ? entriesPerSlide : 3,
							slidesPerGroup: entriesPerSlide ? entriesPerSlide : 3,
							spaceBetween: isFlush ? 0 : 60,
							allowTouchMove: false,
						},
						1280: {
							slidesPerView: entriesPerSlide ? entriesPerSlide : 3,
							slidesPerGroup: entriesPerSlide ? entriesPerSlide : 3,
							allowTouchMove: false,
						},
						1536: {
							slidesPerView: entriesPerSlide ? entriesPerSlide : 3,
							slidesPerGroup: entriesPerSlide ? entriesPerSlide : 3,
							allowTouchMove: false,
						},
						1792: {
							slidesPerView: entriesPerSlide ? entriesPerSlide : 3,
							slidesPerGroup: entriesPerSlide ? entriesPerSlide : 3,
							allowTouchMove: false,
						},
						2048: {
							slidesPerView: entriesPerSlide ? entriesPerSlide : 3,
							slidesPerGroup: entriesPerSlide ? entriesPerSlide : 3,
							allowTouchMove: false,
						},
					}}
					className={clsx("EntryCarousel__slides", "pb-7.5")}
				>
					{Children.map(children, (child, index) => {
						return (
							<SwiperSlide
								key={index}
								className={clsx("EntryCarousel__slide", "h-auto")}
							>
								<Box
									backgroundColor={entryBackgroundColor}
									height={entryBackgroundColor ? "100%" : null}
								>
									{child}
								</Box>
							</SwiperSlide>
						);
					})}
				</Swiper>
				{entriesPerSlide < children.length && (
					<div className="controls">
						<NavButton direction={"prev"} />
						<NavButton direction={"next"} />
					</div>
				)}
			</div>
		</div>
	);
};

export default EntryCarousel;
