import clsx from "clsx";
import parse from "html-react-parser";
import Image from 'next/image'
import { useState } from "react";

import HeadingTag from "@components/HeadingTag";
import getImageBlurOptions from "@lib/getImageBlurOptions";
import CountUp from "react-countup";
import { InView, useInView } from "react-intersection-observer";
import SecondaryButton from "./SecondaryButton";

const StatsGrid = ({
	legacyBackgroundColor,
	backgroundImage,
	backgroundOverlay,
	backgroundImageBlur,
	heading,
	headingTag = "h2",
	stats,
	text,
	textColor = "currentColor",
	statsPosition = "full",
	fullHeight = false,
	statTextColor = "currentColor",
	headingSize = "xl",
	target,
	buttonTextColor,
	legacyButtonBackgroundColor,
	labelColor,
}) => {
	const [ref, inView, entry] = useInView({
		/* Optional options */
		threshold: 0,
	});

	return (
		<div
			className={clsx("StatsGrid relative")}
			style={{ backgroundColor: legacyBackgroundColor, color: textColor }}
		>
			<div
				className={clsx(
					"StatsGrid__container",
					" mx-auto",
					{ "px-5": statsPosition === "full" },
					{
						"max-w-[1780px]": !!backgroundOverlay && statsPosition === "full",
						"max-w-screen-xl": !backgroundOverlay && statsPosition === "full",
						"max-w-screen-3xl": statsPosition !== "full",
					}
				)}
			>
				{statsPosition === "full" && (
					<div
						className={clsx(
							"StatsGrid__header",
							"relative z-10",
							"pt-7.5 lg:pt-15 lg:mb-5"
						)}
						style={{ color: textColor }}
					>
						<div
							className={clsx(
								"StatsGrid__header",
								"md:sticky md:top-36 text-center"
							)}
						>
							<HeadingTag
								tag={headingTag}
								className={clsx("StatsGrid__heading", "relative", {
									"mb-7.5 3xl:mb-10": text,
									"text-3xl md:text-4xl lg:text-5xl 3xl:text-5xl":
										!headingSize || headingSize === "xl",
									"text-3xl": headingSize === "lg",
									"text-2xl": headingSize === "md",
									"text-xl": headingSize === "sm",
								})}
								style={{ color: textColor }}
							>
								{heading}
							</HeadingTag>

              {!!text && (
                <div
                  className={clsx("StatsGrid__text prose mx-auto")}
                  style={{ color: textColor }}
                >
                  {parse(text)}
                </div>
              )}
						</div>
					</div>
				)}

				<div
					className={clsx(
						"StatsGrid__content relative flex flex-col md:flex-row flex-col-reverse ",
						{ "md:flex-row-reverse": statsPosition !== "left" }
					)}
				>
					{!!backgroundOverlay && (
						<div
							className={clsx(
								"StatsGrid__backgroundOverlay-container",
								"w-full max-w-[550px]",
								"items-end",
								"flex",
								"basis-1/3"
							)}
						>
							<div
								className={clsx(
									"StatsGrid__backgroundOverlay",
									"w-full",
									`aspect-w-11 aspect-h-10`
								)}
							>
								<Image
									src={backgroundOverlay.url}
									layout="fill"
									objectFit="contain"
									alt={backgroundOverlay.title}
								/>
							</div>
						</div>
					)}
					<div
						className={clsx(
							"StatsGrid__stats",
							"md:grid",
							"relative z-10",
							{
								"py-5": statsPosition === "full",
								"md:py-15": statsPosition !== "full",
							},
							"text-center",
							{
								"text-white-01": statsPosition !== "full",
							},
							{
								"gap-7.5": statsPosition === "full",
								"gap-7.5 lg:gap-15": statsPosition !== "full",
							},
							{
								"md:grid-cols-2":
									stats.length === 2 || statsPosition !== "full",
								"md:grid-cols-3":
									statsPosition === "full" &&
									(stats.length === 3 || stats.length > 4),
								"md:grid-cols-4":
									statsPosition === "full" && stats.length === 4,
							},
							{
								"basis-2/3 max-w-[900px] md:text-left":
									!!backgroundOverlay && statsPosition !== "full",
							},
							"content-center",
							{
								"md:pl-5 max-w-[1780px] lg:gap-15":
									!!backgroundOverlay && statsPosition === "full",
								"max-w-screen-xl w-full":
									!backgroundOverlay && statsPosition === "full",
								"w-full md:w-1/2": statsPosition !== "full",
							}
						)}
					>
						{stats.map((stat, index) => {
							return (
								<div
									key={stat.id}
									className={clsx(
										"StatsGrid__statmd:mb-0 relative z-10",
										{ "mb-5": statsPosition === "full" },
										{ "my-15 md:mb-0": statsPosition !== "full" }
									)}
								>
									<div
										className={clsx(
											"StatsGrid__figure",
											"w-full",
											"xl:mb-7.5",
											{
												"text-6xl xl:text-8xl": stats.length >= 4,
												"text-7xl xl:text-9xl": stats.length < 4,
											}
										)}
										style={{ color: statTextColor }}
										ref={ref}
									>
										{(stat.figure.match(/[a-zA-Z%/+]+|[0-9]+/g)[0] ==
											parseInt(
												stat.figure.match(/[a-zA-Z%/+]+|[0-9]+/g)[0],
												10
											) ||
											stat.figure.match(/[a-zA-Z%/+]+|[0-9]+/g).length > 1) && (
											<div>
												<CountUp
													start={inView ? 0 : undefined}
													end={stat.figure.match(/[a-zA-Z%/+]+|[0-9]+/g)[0]}
													delay={index / 2}
													duration={2}
												/>
												{stat.figure.match(/[a-zA-Z%/+]+|[0-9]+/g)[1]}
											</div>
										)}

										{stat.figure.match(/[a-zA-Z%/+]+|[0-9]+/g)[0] !=
											parseInt(
												stat.figure.match(/[a-zA-Z%/+]+|[0-9]+/g)[0],
												10
											) &&
											stat.figure.match(/[a-zA-Z%/+]+|[0-9]+/g).length == 1 &&
											stat.figure}
									</div>
									<div
										className={clsx(
											"StatsGrid__label",
											"text-base",
											{ "md:max-w-[350px]": statsPosition === "full" },
											{ "md:max-w-[240px]": statsPosition !== "full" },
											{ "mx-auto": !backgroundOverlay }
										)}
										style={{ color: labelColor }}
									>
										{stat.label}
									</div>
								</div>
							);
						})}
						{statsPosition === "full" && (
							<div
								className={clsx(
									"StatsGrid__footer",
									"relative z-10",
									{
										"col-span-2": stats.length === 2,
										"col-span-3": stats.length === 3,
										"col-span-4": stats.length === 4,
									},
									{ "py-7.5": !backgroundOverlay },
									{ "py-5 md:py-0": !!backgroundOverlay }
								)}
							>
								<SecondaryButton
									target={target}
									legacyBackgroundColor={legacyButtonBackgroundColor}
									textColor={buttonTextColor}
								/>
							</div>
						)}
						{statsPosition !== "full" && !!backgroundImage?.url && (
							<div
								className={clsx(
									"StatsGrid__backgroundImage h-full w-full max-w-screen-3xl m-auto inset-0 absolute"
								)}
							>
								<Image
									src={backgroundImage.url}
									layout="fill"
									objectFit="cover"
									alt={backgroundImage.title}
									draggable={false}
									{...getImageBlurOptions(backgroundImage, backgroundImageBlur)}
								/>
							</div>
						)}
					</div>
					{statsPosition !== "full" && (
						<div
							className={clsx(
								"StatsGrid__text-content w-full md:w-1/2 py-15 relative flex",
								{
									"justify-items": statsPosition === "right",
								},
								"relative z-10",
								"md:max-w-screen-half",
								"px-7.5 md:px-15",
								{
									"md:pl-5": statsPosition === "right",
									"md:pr-5": statsPosition === "left",
								}
							)}
							style={{ color: textColor }}
						>
							<div className={clsx("StatsGrid__header", "md:sticky md:top-36")}>
								<HeadingTag
									tag={headingTag}
									className={clsx("StatsGrid__heading", "relative", {
										"mb-7.5 3xl:mb-10": text,
										"text-3xl md:text-4xl lg:text-5xl 3xl:text-5xl":
											!headingSize || headingSize === "xl",
										"text-3xl": headingSize === "lg",
										"text-2xl": headingSize === "md",
										"text-xl": headingSize === "sm",
									})}
									style={{ color: textColor }}
								>
									{heading}
								</HeadingTag>

                {!!text && (
                  <div
                    className={clsx("StatsGrid__text prose")}
                    style={{ color: textColor }}
                  >
                    {parse(text)}
                  </div>
                )}
							</div>
						</div>
					)}
				</div>

				{!!backgroundImage?.url && statsPosition === "full" && (
					<div
						className={clsx(
							"StatsGrid__backgroundImage h-full w-full max-w-screen-3xl m-auto inset-0 absolute"
						)}
					>
						<Image
							src={backgroundImage.url}
							layout="fill"
							objectFit="cover"
							alt={backgroundImage.title}
							draggable={false}
							{...getImageBlurOptions(backgroundImage, backgroundImageBlur)}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default StatsGrid;
