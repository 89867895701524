import clsx from "clsx";

const MaxWidth = ({
	maxWidth = "max-w-screen-xl",
	children,
	className,
	style,
}) => {
	return (
		<div
			className={clsx("MaxWidth-Container", className, {
				"max-w-screen-xl": maxWidth === "max-w-screen-xl",
				"max-w-prose": maxWidth === "max-w-prose",
				"max-w-xs": maxWidth === "max-w-xs",
				"max-w-sm": maxWidth === "max-w-sm",
				"max-w-md": maxWidth === "max-w-md",
				"max-w-lg": maxWidth === "max-w-lg",
				"max-w-xl": maxWidth === "max-w-xl",
				"max-w-screen-half": maxWidth === "max-w-screen-half",
				"max-w-screen-sm": maxWidth === "max-w-screen-sm",
				"max-w-screen-md": maxWidth === "max-w-screen-md",
				"max-w-screen-lg": maxWidth === "max-w-screen-lg",
				"max-w-screen-2xl": maxWidth === "max-w-screen-2xl",
				"max-w-screen-3xl": maxWidth === "max-w-screen-3xl",
			})}
			style={style}
		>
			{children}
		</div>
	);
};

export default MaxWidth;
