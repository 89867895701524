import clsx from "clsx";
import Image from "next/image";
import SVG from "react-inlinesvg";

import { Flex } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import ClipLoader from "react-spinners/ClipLoader";
import PageSections from "../../apps/abnormal/components/PageSections";
import { blockSpacing } from "../lib/getBlockSpacing";

const ImageAndContent = ({
	image,
	maxWidthPixels,
	children,
	reverseOrder = false,
	verticalAlignment = "center",
	gridGap,
	roundedImage = false,
	blockPadding,
	mobileBlockPadding,
	blockMargin,
	mobileBlockMargin,
	globals,
}) => {
	return (
		<Flex
			className={clsx("ImageAndContent")}
			align={{ base: "flex-start", md: verticalAlignment }}
			w="100%"
			gap={gridGap && { base: "xs", md: gridGap }}
			flexDirection={{
				base: reverseOrder ? "column-reverse" : "column",
				md: reverseOrder ? "row-reverse" : "row",
			}}
			{...blockSpacing(
				blockPadding,
				mobileBlockPadding,
				blockMargin,
				mobileBlockMargin
			)}
		>
			<Flex
				className={clsx("ImageAndContent__imageWrapper")}
				textAlign={"center"}
				flexShrink={reverseOrder ? 0 : 1}
				flexGrow={reverseOrder ? 1 : 0}
				align={verticalAlignment}
				maxWidth={maxWidthPixels}
			>
				{image?.extension === "svg" && (
					<>
						{image.svgContent ? (
							<SVG
								loader={<ClipLoader />}
								className="svgEmbed"
								shapeRendering="geometricprecision"
								src={image.svgContent}
								uniqueHash={image.id}
								uniquifyIDs={true}
								preProcessor={(code) => code.replace(/font-family=".*?"/g, "")}
							/>
						) : (
							<ReactSVG
								loading={() => <ClipLoader />}
								beforeInjection={(svg) => {
									svg.setAttribute("style", "max-width: 100%; height: auto;");
									svg.setAttribute("shape-rendering", "geometricPrecision");
									svg.querySelectorAll("*").forEach((x) => {
										x.removeAttribute("font-family");
									});
								}}
								className={clsx("inline-flex")}
								src={
									process.env.NODE_ENV === "development"
										? "/servd-volumes/images/" + image.path
										: image.url
								}
							/>
						)}
					</>
				)}

				{image && image?.extension !== "svg" && (
					<Image
						src={image.url}
						width={image.width}
						height={image.height}
						alt={image.title}
						className={clsx("ImageAndContent__image", {
							"rounded-full overflow-hidden": roundedImage,
						})}
						blurDataURL={image.blur ? image.blur[0].url : null}
					/>
				)}
			</Flex>
			<Flex
				className="ImageAndContent__content"
				flexGrow={reverseOrder ? 0 : 1}
				align={verticalAlignment}
				flexDirection="column"
			>
				{children && (
					<PageSections sections={children} isPageModule globals={globals} />
				)}
			</Flex>
		</Flex>
	);
};

export default ImageAndContent;
