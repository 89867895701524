import debounce from "lodash/debounce";
import { useEffect, useState } from "react";

/*  ------------------------------ */
/*  Client helpers
/*  ------------------------------ */

export const Keys = {
  ENTER: 13,
  SPACE: 32,
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
};

export const isBrowser = typeof window !== "undefined";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Debounce to avoid the function fire multiple times
    const handleResizeDebounced = debounce(
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      },
      500,
      false
    );

    window.addEventListener("resize", handleResizeDebounced);

    // Call handler right away so state gets updated with initial window size
    handleResizeDebounced();

    return () => window.removeEventListener("resize", handleResizeDebounced);
  }, []);

  return windowSize;
};
