import CountUp from 'react-countup';

const StatCounter = ({ content, inView }) => {
  return <>
  {((content.match(/[a-zA-Z%/+]+|[0-9]+/g)[0] == parseInt(content.match(/[a-zA-Z%/+]+|[0-9]+/g)[0], 10) || content.match(/[a-zA-Z%/+]+|[0-9]+/g).length > 1)) && (
      <div>
        {inView ? <CountUp start={0} end={content.match(/[a-zA-Z%/+]+|[0-9]+/g)[0]} duration={2} /> : 0}
        {content.match(/[a-zA-Z%/+]+|[0-9]+/g)[1]}
      </div>
  )}

  {((content.match(/[a-zA-Z%/+]+|[0-9]+/g)[0] != parseInt(content.match(/[a-zA-Z%/+]+|[0-9]+/g)[0], 10) && content.match(/[a-zA-Z%/+]+|[0-9]+/g).length == 1)) && content}
  </>;
};

export default StatCounter;
