import { Tag as TagComponent, HStack } from "@chakra-ui/react";

const Tag = ({ tagContent }) => {
	return (
		<HStack spacing="1">
			{tagContent.map((item, index) => {
				return (
					<TagComponent
						size="md"
						rounded="2"
						color={item.textColor}
						backgroundColor={item.backgroundColor}
						key={index}
						px="3.5"
						py="2"
					>
						{item.tagText}
					</TagComponent>
				);
			})}
		</HStack>
	);
};

export default Tag;
