import Chroma from "chroma-js";

export const isColorDark = (hex) => {
  if (!hex || hex === "transparent") return false;
  //console.log(hex)
  if (hex === "#924e8c") return false;
  const color = Chroma(hex);
  return color.get("lab.l") < 70;
};

export default isColorDark;
