import clsx from "clsx";

import Review from "@components/Review";

interface IProps {
  children: object;
  borderType: string;
  borderColor: string;
}

const ReviewsColumn = ({ children, borderType, borderColor }) => {
  return (
    <div
      className={clsx(
        "Reviews__columnContent w-full h-full flex flex-col justify-center"
      )}
    >
      {children.map((review, index) => {
        return (
          <div
            key={review.id}
            className={clsx(
              "Reviews__review flex flex-col justify-center h-full ",
              {
                "border-b":
                  borderType === "complete" && index + 1 !== children.length,
                "md:h-full": children.length === 1,
                "md:h-1/2": children.length === 2,
                "md:h-1/3": children.length === 3,
                "md:h-1/4": children.length === 4,
                "py-7.5 px-7.5": borderType === "complete",
              }
            )}
            style={{
              borderColor:
                borderColor !== "transparent" && borderType === "complete"
                  ? borderColor
                  : "transparent",
            }}
          >
            <Review
              text={review.text}
              size={review.size}
              target={review.target}
              stars={review.stars}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ReviewsColumn;
