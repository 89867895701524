import clsx from "clsx";
import { A11y, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import LogoBlock from "@components/LogoBlock";
import { useState } from "react";
import { ArrowLeft, ArrowRight } from "./Icons";

const LogoCarousel = ({
	logos,
	legacyBackgroundColor,
	verticalPadding = "none",
	slideHeight = 60,
	slidesPerView = 8,
}) => {
	const [swiper, setSwiper] = useState(null);
	const [isBeginning, setIsBeginning] = useState(true);
	const [isEnd, setIsEnd] = useState(false);
	const [offset, setOffset] = useState(null);

	const handleSlideChange = () => {
		setIsBeginning(swiper.isBeginning);
		setIsEnd(swiper.isEnd);
	};

	const NavButton = ({ direction = "next" }) => {
		const handleClick = () => {
			if (direction === "next") swiper.slideNext();
			if (direction === "prev") swiper.slidePrev();
		};

		return (
			<button
				className={clsx(
					"w-12 h-12",
					"bg-white-01",
					"rounded-full",
					"flex items-center justify-center -mt-[24px] top-1/2",
					"absolute z-30",
					"transition-all",
					{
						"opacity-0 pointer-events-none":
							(direction === "prev" && isBeginning) ||
							(direction === "next" && isEnd),
						"right-0": direction === "next",
						"left-0": direction === "prev",
					}
				)}
				onClick={handleClick}
			>
				{direction === "next" ? <ArrowRight /> : <ArrowLeft />}
			</button>
		);
	};

	return (
		<div
			className={clsx("LogoCarousel relative w-full")}
			style={{ backgroundColor: legacyBackgroundColor }}
		>
			<div
				className={clsx(
					"LogoCarousel__content relative px-15 ",
					"select-none",
					{
						"py-7.5": verticalPadding === "sm",
						"py-15": verticalPadding === "md",
						"py-30": verticalPadding === "lg",
						"py-60": verticalPadding === "xl",
					}
				)}
			>
				<Swiper
					modules={[A11y]}
					grabCursor
					a11y={{
						prevSlideMessage: "Previous slide",
						nextSlideMessage: "Next slide",
					}}
					className={clsx("LogoCarousel__slides", "overflow-hidden")}
					spaceBetween={20}
					slidesPerView={1}
					slidesPerGroup={1}
					onSwiper={setSwiper}
					onSlideChange={handleSlideChange}
					breakpoints={{
						640: { slidesPerView: 2, slidesPerGroup: 2 },
						1024: {
							slidesPerView: 4,
							slidesPerGroup: 4,
						},
						1200: {
							slidesPerView: slidesPerView,
							slidesPerGroup: slidesPerView,
							spaceBetween: 20,
						},
					}}
				>
					{logos.map((logo) => {
						const image = logo.image[0];

						return (
							<SwiperSlide
								key={logo.id}
								className={clsx("LogoCarousel__slide")}
							>
								<LogoBlock
									image={image}
									withMargin={false}
									bottomMargin={true}
									imageHeight={slideHeight}
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
			{logos.length > 8 && (
				<div className="controls absolute w-full h-full top-0">
					<NavButton direction={"prev"} />
					<NavButton direction={"next"} />
				</div>
			)}
		</div>
	);
};

export default LogoCarousel;
