import clsx from "clsx";
import Image from 'next/image'

import Target from "@components/Target";

const IconStrip = ({ icons }) => {
  return (
    <div className={clsx("IconStrip")}>
      <div
        className={clsx(
          "IconStrip__icons",
          "flex justify-center",
          "space-x-15"
        )}
      >
        {icons.map((icon) => {
          const image = icon.image[0];

          return (
            <Target key={icon.id} target={icon.target}>
              <Image
                src={image.url}
                width={image.width}
                height={image.height}
                layout="fixed"
                alt={image.title}
              />
            </Target>
          );
        })}
      </div>
    </div>
  );
};

export default IconStrip;
