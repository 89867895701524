import clsx from "clsx";
import { AnimatePresence } from "framer-motion";
import parse from "html-react-parser";
import { useState } from "react";

import EmbeddedForm from "@components/EmbeddedForm";
import MaxWidth from "@components/MaxWidth";
import Message from "@components/Message";

const Form = ({
	form,
	maxWidth,
	textColor,
	legacyBackgroundColor,
	formHeading,
	formDescription,
	formData,
	onFormSuccess = () => null,
	usePlaceholders,
	buttonBackgroundColor,
	buttonTextColor,
}) => {
	const [formSuccess, setFormSuccess] = useState(false);

	const handleFormSuccess = () => {
		onFormSuccess();
		setFormSuccess(true);
	};

	//console.log(formData);

	return (
		<MaxWidth
			className={clsx("Form")}
			style={{ backgroundColor: legacyBackgroundColor, color: textColor }}
			maxWidth={maxWidth}
		>
			<div className={clsx("Form__formContent")}>
				<AnimatePresence>
					{formSuccess && <Message message={form.successMessage} />}
				</AnimatePresence>

				{!!form && !formSuccess && (
					<>
						<h3
							className={clsx("Form__heading", "mb-4", "text-2xl font-medium")}
						>
							{formHeading || form.title}
						</h3>
						{!!formDescription && (
							<div className={clsx("Form__description", "mb-7.5")}>
								{parse(formDescription)}
							</div>
						)}
						<div className={clsx("Form__embeddedForm")}>
							<EmbeddedForm
								form={form}
								formData={formData}
								onSuccess={handleFormSuccess}
								usePlaceholders={usePlaceholders}
								buttonBackgroundColor={buttonBackgroundColor}
								buttonTextColor={buttonTextColor}
							/>
						</div>
					</>
				)}
				{!form && (
					<h3 className={clsx("Form__missingForm", "mb-7.5", "text-3xl")}>
						Form not specified
					</h3>
				)}
			</div>
		</MaxWidth>
	);
};

export default Form;
