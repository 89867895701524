import { Box, Flex, Tab, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { ArrowLeft, ArrowRight } from "../../apps/abnormal/components/Icons";
import PageSections from "../../apps/abnormal/components/PageSections";

const TabsBlock = ({
	children,
	activeTabTextColor,
	inactiveTabTextColor,
	activeTabColor,
	inactiveTabColor,
	headingSize,
	maxWidth = "max-w-screen-xl",
}) => {
	const [initialIndex, setInitialIndex] = useState(null);
	const { query, isReady } = useRouter();
	useEffect(() => {
		if (isReady) {
			let index = 1;
			const tab = Number(query.tab);
			const length = children.length;
			if (tab >= 1 && tab <= length) {
				index = tab;
			}
			setInitialIndex(index);
		}
	}, [query]);

	if (!initialIndex) return null;
	return (
		<Tabs
			variant="horizontal"
			width="full"
			defaultIndex={initialIndex - 1}
			key={initialIndex}
		>
			<Box maxWidth="1200px" mx="auto" width="full">
				<ScrollMenu
					LeftArrow={LeftArrow}
					RightArrow={RightArrow}
					scrollContainerClassName="justify-start md:justify-center flex"
				>
					{children &&
						children.map((tab, index) => {
							return (
								<Tab
									color={
										tab.inactiveTabTextColor
											? tab.inactiveTabTextColor
											: inactiveTabTextColor
									}
									backgroundColor={
										tab.inactiveTabColor
											? tab.inactiveTabColor
											: inactiveTabColor
									}
									key={index}
									fontSize={headingSize}
									_selected={{
										backgroundColor: tab.activeTabColor
											? tab.activeTabColor
											: activeTabColor,
										color: tab.activeTabTextColor
											? tab.activeTabTextColor
											: activeTabTextColor,
									}}
								>
									{tab.heading}
								</Tab>
							);
						})}
				</ScrollMenu>
			</Box>

			<TabPanels>
				{children &&
					children.map((tab, index) => {
						return (
							<TabPanel key={index} backgroundColor={tab.backgroundColor}>
								<Flex
									maxW={maxWidth}
									mx={maxWidth && "auto"}
									flexDirection={"column"}
								>
									{tab.children && (
										<PageSections sections={tab.children} isPageModule={true} />
									)}
								</Flex>
							</TabPanel>
						);
					})}
			</TabPanels>
		</Tabs>
	);
};

function LeftArrow() {
	const { isLastItemVisible, isFirstItemVisible, scrollPrev } =
		useContext(VisibilityContext);

	return (
		<Flex
			opacity={isFirstItemVisible ? 0.2 : 1}
			display={isFirstItemVisible && isLastItemVisible ? "none" : "flex"}
			px={2}
			onClick={() => scrollPrev()}
			alignItems="center"
		>
			<ArrowLeft />
		</Flex>
	);
}

function RightArrow() {
	const { isFirstItemVisible, isLastItemVisible, scrollNext } =
		useContext(VisibilityContext);

	return (
		<Flex
			opacity={isLastItemVisible ? 0.2 : 1}
			display={isFirstItemVisible && isLastItemVisible ? "none" : "flex"}
			onClick={() => scrollNext()}
			px={2}
			alignItems="center"
		>
			<ArrowRight />
		</Flex>
	);
}

export default TabsBlock;
