import PageSectionBlocks from "@components/PageSectionBlocks";
import React, { ReactNode } from "react";

interface IProps {
	section?: any;
	gridPosts?: any[];
	isFirst?: boolean;
	globals?: object;
	callToActions?: object;
}

interface ISectionProps {
	children: ReactNode;
	typeHandle: string;
	legacyBackgroundColor: string;
}

interface IProps {
	sections: any[];
	forms?: any[];
	gridPosts?: any[];
	globals?: object;
	isPageModule?: boolean;
	callToActions?: object;
	allReviews?: any[];
	sectionNav?: boolean;
}

const PageSections = ({
	sections,
	forms = null,
	gridPosts = null,
	globals = null,
	isPageModule = false,
	callToActions,
	allReviews,
	sectionNav = false,
}: IProps): JSX.Element => {
	return (
		<>
			{sections?.map((section, index) => {
				return (
					<PageSectionBlocks
						key={index}
						section={section}
						isFirst={!isPageModule && index === 0}
						gridPosts={gridPosts}
						forms={forms}
						globals={globals}
						allReviews={allReviews}
						callToActions={callToActions}
						sectionNav={sectionNav}
					/>
				);
			})}
		</>
	);
};

export default PageSections;
