import clsx from "clsx";
import parse from "html-react-parser";
import Image from 'next/image'

import HeadingTag from "@components/HeadingTag";
import { ArrowRight } from "@components/Icons";
import SecondaryButton from "@components/SecondaryButton";
import Target from "@components/Target";
import isColorDark from "@lib/isColorDark";
interface IProps {
  items: any[];
  legacyBackgroundColor: string;
  buttonTextColor: string;
  legacyButtonBackgroundColor: string;
  textColor: string;
  heading: string;
  subHeading: string;
  headingTag: "h1" | "h2" | "h3" | "h4";
  headingSize: "xl" | "lg" | "md" | "sm";
  target: any;
}

const IllustrationGrid = ({
  items,
  legacyBackgroundColor = "#0D1418",
  textColor = "#F7F7F7",
  heading,
  headingTag,
  headingSize,
  subHeading,
  target,
  buttonTextColor,
  legacyButtonBackgroundColor
}: IProps): JSX.Element => {
  const isBackgroundColorDark = isColorDark(legacyBackgroundColor) || false;
  
  const Items = () => {
    return (
      <div
        className={clsx(
          "IllustrationGrid__items",
          "max-w-screen-xl",
          "mx-auto",
          "px-5",
          "grid md:grid-cols-3 gap-7.5"
        )}
        style={{ color: textColor }}
      >
        {items.map((item) => {
          const image = item.image[0];

          return (
            <Target
              key={item.id}
              target={item.target}
              className={clsx(
                "IllustrationGrid__item",
                "flex align-center flex-col content-center",
                "p-10",
                "group",
                {
                  "bg-white-01/5": isBackgroundColorDark,
                  "bg-black-01/[.08]": !isBackgroundColorDark
                }
              )}
            >
              <div
                className={clsx(
                  "IllustrationGrid__imageWrapper",
                  "flex justify-center items-center",
                  "mx-auto",
                  "relative",
                  "overflow-hidden",
                  "w-30 lg:w-44 h-30 lg:h-44"
                )}
              >
                <Image
                  src={image.url}
                  alt={image.title}
                  layout="fill"
                  objectFit="contain"
                  className={clsx("IllustrationGrid__image", "w-full block")}
                />
              </div>

              <div
                className={clsx(
                  "IllustrationGrid__caption",
                  "flex-grow",
                  "text-center",
                  "relative",
                  "pt-7.5"
                )}
              >
                <div
                  className={clsx(
                    "IllustrationGrid__itemHeading",
                    "text-xl lg:text-2xl",
                    "max-w-xs m-auto",
                    "hidden md:flex w-full",
                    "transition-opacity",
                    "opacity-0 md:opacity-100 lg:group-hover:opacity-0 group-focus:opacity-0",
                    "lg:absolute inset-0",
                    "flex items-center"
                  )}
                >
                  <span className="block">{item.heading}</span>
                </div>
                <div
                  className={clsx(
                    "IllustrationGrid__reveal",
                    "transition-opacity",
                    "opacity-100 md:opacity-0 group-hover:opacity-100 group-focus:opacity-100 group-active:opacity-100"
                  )}
                >
                  {!!item?.heading && (
                    <div className={clsx("text-green-03")}>
                      {parse(item.heading)}
                    </div>
                  )}
                  {!!item?.text && (
                    <div className={clsx("")}>
                      {parse(item.text)}
                    </div>
                  )}
                  {!!item.target && !!item.target.url && (
                    <div className={clsx("mx-auto", "flex justify-center")}>
                      <ArrowRight />
                    </div>
                  )}
                </div>
              </div>
            </Target>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={clsx("IllustrationGrid")}
      style={{ backgroundColor: legacyBackgroundColor, color: textColor }}
    >
      <div
        className={clsx("IllustrationGrid__header", "py-15 lg:py-15 px-7.5")}
      >
        <HeadingTag
          tag={headingTag}
          className={clsx(
            "IllustrationGrid__heading",
            "text-3xl lg:text-4xl 3xl:text-5xl text-center",
            {"mb-5": subHeading}
          )}
        >
          {heading}
        </HeadingTag>

        {subHeading && (
          <HeadingTag
            tag="h3"
            className={clsx(
              "IllustrationGrid__heading",
              "text-xl text-center"
            )}
          >
            {subHeading}
          </HeadingTag>
        )}
        
      </div>
      <div
        className={clsx(
          "IllustrationGrid__body",
        )}
      >
        <Items />
      </div>
      <div
        className={clsx(
          "IllustrationGrid__footer",
          "flex justify-center py-7.5 md:py-15 px-5"
        )}
      >
        <SecondaryButton
          target={target}
          legacyBackgroundColor={legacyButtonBackgroundColor}
          textColor={buttonTextColor}
        />
      </div>
    </div>
  );
};

export default IllustrationGrid;
