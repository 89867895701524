import clsx from "clsx";
import parse from "html-react-parser";
import Image from 'next/image'
import { useState } from "react";
import { A11y, Autoplay, EffectFade, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import getImageBlurOptions from "@lib/getImageBlurOptions";
import SecondaryButton from "./SecondaryButton";

const Testimonials = ({
	testimonials,
	useLogoNavigation = false,
	legacyBackgroundColor,
	textColor = "#0D1418",
	attributionColor = "#E5E7E7",
	backgroundImage,
	backgroundImageBlur,
	target,
	legacyButtonBackgroundColor,
	buttonTextColor,
}) => {
	const [swiper, setSwiper] = useState(null);
	const [activeTestimonial, setActiveTestimonial] = useState(testimonials[0]);

	const handleLogoClick = (index) => {
		setActiveTestimonial(testimonials[index]);
		swiper.slideTo(index);
	};

	return (
		<div
			className={clsx("Testimonials relative")}
			style={{ backgroundColor: legacyBackgroundColor, color: textColor }}
		>
			<div className={clsx("Testimonials__content")}>
				<div
					className={clsx("Testimonials__main", {
						"centered-slides": !useLogoNavigation,
					})}
				>
					<Swiper
						centeredSlides={true}
						enabled={testimonials.length > 1}
						pagination={!useLogoNavigation && { clickable: true }}
						a11y={{
							prevSlideMessage: "Previous slide",
							nextSlideMessage: "Next slide",
						}}
						modules={[Pagination, A11y, EffectFade, Autoplay]}
						effect="fade"
						fadeEffect={{ crossFade: true }}
						breakpoints={{
							768: {
								effect: "fade",
							},
						}}
						autoplay={{
							delay: 8000,
							pauseOnMouseEnter: true,
							disableOnInteraction: false,
						}}
						onSwiper={setSwiper}
						slidesPerView={1}
						onSlideChange={(swiper) =>
							setActiveTestimonial(testimonials[swiper.activeIndex])
						}
					>
						{testimonials.map((testimonial) => {
							const image = testimonial.image[0];
							const logo = testimonial.logo[0];

							return (
								<SwiperSlide key={testimonial.id}>
									<div
										className={clsx(
											"Testimonials__testimonial max-w-screen-xl mx-auto",
											{
												"flex flex-col md:flex-row lg:px-7.5": !!image,
											}
										)}
									>
										{image && (
											<div
												className={clsx(
													"Testimonials__media",
													"md:w-1/2",
													"px-7.5"
												)}
											>
												<Image
													key={image.id}
													className={clsx("Testimonial__image")}
													src={image.url}
													width={image.width}
													height={image.height}
													alt={image.title}
													layout="responsive"
												/>
											</div>
										)}

										<div
											className={clsx(
												"Testimonial__body",
												{
													"md:w-1/2": !!image,
												},
												"p-7.5 md:p-0 md:px-7.5 lg:p-15"
											)}
										>
											{logo && (
												<div
													className={clsx(
														"Testimonial__logo",
														"mb-7.5",
														"h-[30px]",
														"relative",
														"hidden md:block"
													)}
												>
													<Image
														src={logo.url}
														height={30}
														width={(30 / logo.height) * logo.width}
														alt={logo.title}
														layout="fixed"
													/>
												</div>
											)}

                      {!!testimonial?.quotation && (
                        <div
                          className={clsx(
                            "Testimonial__quotation",
                            {
                              "max-w-lg": !!image,
                              "text-2xl md:text-4xl text-center": !image,
                            },
                            "mb-7.5"
                          )}
                        >
                          {parse(testimonial.quotation)}
                        </div>
                      )}
                      {!!testimonial?.attribution && (
                        <div
                          style={{ color: attributionColor }}
                          className={clsx("Testimonial__attribution", "text-ss", {
                            "text-center": !image,
                          })}
                        >
                          {parse(testimonial.attribution)}
                        </div>
                      )}
                      {!!testimonial?.attributionRole && (
                        <div
                          style={{ color: attributionColor }}
                          className={clsx(
                            "Testimonial__attributionRole",
                            "text-ss",
                            {
                              "text-center": !image,
                              "opacity-25": !textColor,
                            }
                          )}
                        >
                          {parse(testimonial.attributionRole)}
                        </div>
                      )}
										</div>
									</div>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</div>
				{useLogoNavigation && (
					<div
						className={clsx("Testimonials__navigation", "md:py-7.5 lg:py-15")}
					>
						<div
							className={clsx(
								"Testimonials__navigationLogos",
								"flex justify-center mx-auto flex-wrap"
							)}
						>
							{testimonials.map((testimonial, index) => {
								const logo = testimonial.logo[0];
								const logoBlur = testimonial.logoBlur[0];
								const isCurrent = activeTestimonial.id === testimonial.id;

								return (
									<button
										key={testimonial.id}
										className={clsx(
											"Testimonials__navigationLogo",
											"relative",
											"cursor-pointer",
											"hover:opacity-100",
											"mx-4 my-4 md:mx-7.5 lg:mx-15",
											{
												"opacity-100": isCurrent,
												"opacity-20": !isCurrent,
											}
										)}
										onClick={() => handleLogoClick(index)}
										style={{ width: logo.width / 4, height: logo.height / 4 }}
									>
										<Image
											src={logo.url}
											alt={logo.title}
											layout="fill"
											objectFit="contain"
											{...getImageBlurOptions(logo, logoBlur)}
										/>
									</button>
								);
							})}
						</div>
					</div>
				)}

				{!!target.url && (
					<div
						className={clsx(
							"Testimonials__targets z-10 relative text-center",
							"lg:mb-0 pb-7.5 lg:pb-15 mx-5"
						)}
					>
						{!!target && !!target.url && (
							<div className={clsx("Testimonials__target")}>
								<SecondaryButton
									target={target}
									legacyBackgroundColor={legacyButtonBackgroundColor}
									textColor={buttonTextColor}
								/>
							</div>
						)}
					</div>
				)}
			</div>
			{!!backgroundImage?.url && (
				<div
					className={clsx(
						"Testimonials__backgroundImage",
						"absolute inset-0 z-0 h-full"
					)}
				>
					<Image
						src={backgroundImage.url}
						layout="fill"
						objectFit="cover"
						alt={backgroundImage.title}
						draggable={false}
						{...getImageBlurOptions(backgroundImage, backgroundImageBlur)}
					/>
				</div>
			)}
		</div>
	);
};

export default Testimonials;
