import DefaultBlocks from "@sharedComponents/DefaultBlocks";
import React from "react";

interface IProps {
	allReviews?: any[];
	callToActions?: object;
	columnWidth?: string;
	containerMaxWidth?: string;
	forms?: any[];
	globals?: object;
	gridColumns?: number;
	gridMode?: boolean;
	gridPosts?: any[];
	isFirst?: boolean;
	isFirstContainer?: boolean;
	section?: any;
	sectionNav?: boolean;
	subColumn?: boolean;
}

const PageSectionBlocks = ({
	allReviews,
	callToActions = null,
	columnWidth,
	containerMaxWidth,
	forms = null,
	globals = null,
	gridColumns,
	gridMode = false,
	gridPosts = null,
	isFirst = false,
	isFirstContainer,
	section,
	sectionNav,
}: IProps) => {
	switch (section.typeHandle) {
		// Override default blocks here.
		//case "blockHandleHere":

		default:
			return (
				<DefaultBlocks
					section={section}
					isFirst={isFirst}
					gridPosts={gridPosts}
					forms={forms}
					globals={globals}
					allReviews={allReviews}
					gridMode={gridMode}
					gridColumns={gridColumns}
					containerMaxWidth={containerMaxWidth}
					columnWidth={columnWidth}
					isFirstContainer={isFirstContainer}
					callToActions={callToActions}
					sectionNav={sectionNav}
				/>
			);
	}
};

export default PageSectionBlocks;
